.ticketConfirmationPopupContainer{
    position: fixed;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.ticketConfirmationPopupChoiceContainer{
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    display: flex;
    width: 90%;
    height: 90%;
    background-color: rgb(240, 240, 240);
    border: 3px solid black;
    border-radius: 25px;
}

.ticketConfirmationPopupDisplayData{
    display: flex;
    flex-direction: column;
    max-height: 80%;
}

.ticketConfirmationPopupChoiceContainer h1{
    margin: 0;
    padding-top: 5px;
    padding-bottom: 10px;
}

.ticketConfirmationPopupDisplayData p{
    padding-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    width: auto;
}

.ticketConfirmationPopupDisplayDataImageContainer, .ticketConfirmationPopupDisplayDataButtonsContainer{
    display: flex;
    width: 100%;

}

.ticketConfirmationPopupDisplayDataImageContainer {
    flex-direction: column;
    align-items: center;
    height: auto;
}

.ticketConfirmationPopupDisplayData img{
    object-fit: cover;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-height: 70%;
    border: 2px solid black;
    border-radius: 15px;
}

.ticketConfirmationPopupDisplayDataButtonsContainer {
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 10px;
}