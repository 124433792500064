.submissionPageContainer{
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.submissionPageThumbsUp{
    font-size: 300px;
}

.submissionPageContainer p, .submissionPageContainer h1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.submissionPageContainer p{
    font-weight: bold;
}