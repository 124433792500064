.noPageFoundContainer{
    background-color: #023368;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
}

.noPageFoundText{
    color: white
}

.pageNotFoundHeader{
    font-size: 80px;
    margin: 0;
    margin-top: 50px;
}

.pageNotFoundTextContainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.pageNotFoundParagraph{
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
}


.noPageFoundContainer{
    margin-left: auto;
    margin-right: auto;
}

.imagePageNotFound {
    width: 100%;
}

@media(min-aspect-ratio: 16/10) {

    .noPageFoundContainer{
        justify-content: center;
    }

    .pageNotFoundHeader{
        font-size: 150px;
    }

    .pageNotFoundHeader{
        margin: 0;
    }

    .pageNotFoundParagraph{
        margin-bottom: 200px;
    }

}