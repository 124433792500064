/* create base css for phone screens that overrides defaults when certain aspect ratios are met */
.homePageContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homePageInfoContainer{
    width: 90%;
    border: 2px solid black;
    border-radius: 20px;
    background-color: rgb(233, 233, 233);
    box-shadow: 3px 3px 5px 0px #878787;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homePage_HeaderContainer, .homePage_ParagraphContainer{
    width: 80%;
}

.homePage_ParagraphContainer{
    text-align: left;
}

/* square aspect ratio */
@media(min-aspect-ratio: 16/16) {

    .homePageInfoContainer{
        width: 80%;
    }
    
}

/* wider than tall */
@media(min-aspect-ratio: 16/12) {

    .homePageInfoContainer{
        width: 50%;
    }
    
}
