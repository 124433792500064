/* css for all window sizes */
.serviceTicketClockInContainer{
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.serviceTicketSearchInfoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.serviceTicketInfoContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.serviceTicketSearchMessage{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid black;
    border-radius: 10px;
}

/* css for windows 700px or smaller */
@media (max-width: 750px) {

    .serviceTicketSearchInfoContainer_ButtonContainer, .serviceTicketSearchInfoContainer_TicketCountContainer {
        width: 75%
    }

}

/* css for window larger than 700px and smaller than 1200px */
@media (min-width: 751px) and (max-width: 1200px) {

    .serviceTicketSearchInfoContainer_ButtonContainer {
        width: 30%;
    }
    
    .serviceTicketSearchInfoContainer_TicketCountContainer {
        width: 65%
    }

    .serviceTicketInfoContainer{
        justify-content: space-around;
    }
    
}

/* css for window larger than 1200px */
@media (min-width: 1201px) {

    .serviceTicketSearchInfoContainer_ButtonContainer {
        width: 30%;
    }
    
    .serviceTicketSearchInfoContainer_TicketCountContainer {
        width: 65%
    }

    .serviceTicketInfoContainer{
        justify-content: space-around;
    }

}