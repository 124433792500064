.logoAndQuestionTrackingContainer{
    display: flex;
}

.logoContainer{
    padding-left: 10px;
    padding-right: 10px;
    width:25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questionTrackingContainer{
    width: 75%;
}

/* 
    because the parent of the this a flex,
    width 100% stretches or shrinks the image
    to match the size of its parent
*/
.logoContainer img{
    width: 100%;
}

.progressBarUiElementContainer{

    position: relative; /* Allows us to position any elements inside of it absolutely, relative to this box */
    overflow: hidden; /* hides any excess stuff if there is any */
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    
}

.questionProgressNumberTrackerOverlay{
    margin: 0;
    padding: 0;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    font-weight: bold;
    /* text-shadow: 
        1px 1px 0px #A7CAED,
        -1px -1px 0px #A7CAED,
        -1px 1px 0px #A7CAED,
        1px -1px 0px #A7CAED,
        1px 0px 0px #A7CAED,
        -1px 0px 0px #A7CAED,
        0px 1px 0px #A7CAED,
        0px -1px 0px #A7CAED;; */

}

.questionProgressNumberTrackerText{
    margin: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto
}