.satisfactionSurveyContainer{
    background-color: #ffffff;
    height: 100%;
    display:flex;
    flex-direction: column;
}

.surveyButtons{
    width: 45%;
    height: 50px;
}

.surveyHeader{
    margin: 0;
}

.formContainer{
    padding-top: 5px;
    align-items: stretch;
    flex-grow: 1;
    display:flex;
    flex-direction: column;
}

.navigationBarContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    /* border: 2px solid black; */
}

.navigationButtonContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bababa;
}

.backButtonContainer{

    border-right: 1px solid black;
    

}


.nextButtonContainer{

    border-left: 1px solid black;

}

.navigationButtonHeader{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    
}

.backButtonText {
    opacity: .25;
}

.nextButtonText {
    opacity: 1;
}

@media(min-aspect-ratio: 16/10) {

    .satisfactionSurveyContainer{
        width: 50%;
        height: 95%;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 4px 4px 20px 1px;
    }

    /* basically does nothing but I think I like it clearly not being cut off as opposed to it kinda looking cut off */
    .navigationBarContainer {
        margin-bottom: 0px;
    }

}