.surveyHeaderContainer{  
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid black;
    font-size: 20px;
    justify-content: space-around;
}

.surveyHeaderLogoContainer{
    display: flex; /* allows child width to be 100% to grow and shrink image to fit */
    align-items: center;
    justify-content: center;
}

.groupLogo {
    width: 100%;
}

/* grows to fill the width of the container */
.surveyHeaderTextContainer{
    text-align: left;
}


/* css for mobile */
@media (max-width: 600px) {
    
    .surveyHeaderLogoContainer{
        width: 30%;
    }
    
    .surveyHeaderTextContainer{
        width: 65%;
    }
    
}

/* css for desktop */
@media (min-width: 601px) {

    .surveyHeaderContainer{

        justify-content: space-evenly;
        
    }
    
    .surveyHeaderLogoContainer{

        max-width: 200px;
        
    }

}