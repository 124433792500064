.submitScreenContainer{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.50);
    z-index: 9999;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: black;
    text-align: center;
    pointer-events: auto;
}

.submitUiContainer{
    width: 85%;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
}

.submitUiSurveyName{
    font-weight: bold;
    font-size: 2em;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 40px;
}

.submitUiMessageContainer{
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.submitUiButtonContainer{
    margin-top: 40px;
    margin-bottom: 20px;
}

@media(min-aspect-ratio: 16/10) {
    .submitUiContainer{
        width:40%;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}