/* creates default settings for phone sizing then changes the settings depending on aspect ratio */
.globalRequestLimitContainer, .individualRequestLimitContainer{
    height: 100%;
    background-color: rgb(220 0 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.requestLimit_HeaderContainer h1, .requestLimit_ParagraphContainer p{
    margin: 0;
    color: white;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.requestLimit_HeaderContainer h1{
    margin-top: 20px;
    margin-bottom: 20px;
}

.requestLimit_ParagraphContainer p{
    text-align: left;
}

/* square aspect ratio */
@media(min-aspect-ratio: 16/16) {

    .globalRequestLimitContainer, .individualRequestLimitContainer{

    }

    .requestLimit_HeaderContainer h1, .requestLimit_ParagraphContainer p{
        width: 80%;
    }

    .requestLimit_HeaderContainer h1{
        font-size: 50px;
    }
    
    .requestLimit_ParagraphContainer p{
        font-size: 18px;
    }

}

/* computer monitor ratio */
@media(min-aspect-ratio: 16/12) {

    .globalRequestLimitContainer, .individualRequestLimitContainer{

    }

    .requestLimit_HeaderContainer h1, .requestLimit_ParagraphContainer p{
        width: 60%;
    }

    .requestLimit_HeaderContainer h1{
        font-size: 65px;
    }
    
    .requestLimit_ParagraphContainer p{
        font-size: 20px;
        text-align: center;
    }

}