.radioSelectionOption{
    width: 100%;
}

@media(min-aspect-ratio: 16/10) {

    .radioSelectionOption {
        width: auto;
    }

}

.formOptionsContainer{
    flex-grow: 1;
    /* height: 80%; */
}
