.individualServiceTicketContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 20px;
    width: 95%;
    border: 1px solid black;
    margin-bottom: 10px;
}

.renderedServiceTicketContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
}

.renderedServiceTicketContainer p {
    margin: 0;
    padding-bottom: 3px;
}

.renderedServiceTicket_DescriptionContainer, .renderServiceTicket_ButtonContainer{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.renderedServiceTicket_DescriptionContainer{
    text-align: left;
}

.renderedServiceTicket_ImageContainer, .renderedServiceTicket_ExtraInfoContainer{
    width: 50%;
}

.serviceTicketImage{
    max-width: 125px
}

.renderedServiceTicket_ExtraInfoContainer_Age, 
.renderedServiceTicket_ExtraInfoContainer_Status, 
.renderedServiceTicket_DescriptionContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.renderServiceTicket_ButtonContainer{
    display: flex;
    justify-content: center;
}

.renderServiceTicketButton{
    width: 150px
}

.dispositionFieldContainer{
    margin-top: 7px;
    margin-bottom: 3px;
    width: 100%;
}

.textFieldTest__label{
    color: #ffffff00;
}

/* css for window larger than 700px and smaller than 1200px */
@media (min-width: 751px) and (max-width: 1200px) {
    
    .individualServiceTicketContainer{
        width: 45%;
        
    }
    
}

/* css for window larger than 1200px */
@media (min-width: 1201px) {

    .individualServiceTicketContainer{
        width: 30%
    }

}